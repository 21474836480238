<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <section class="body">
      <h2 class="section-title">{{ name }}</h2>
      <ul class="nav nav-pills mb-4" id="myTab3" role="tablist">
        <li class="nav-item">
          <a
            @click="selectType('income_statement')"
            class="nav-link"
            :class="type == 'income_statement' ? 'active show' : ''"
            id="home-tab3"
            data-toggle="tab"
            href="#home3"
            role="tab"
            aria-controls="home"
            aria-selected="false"
            >Laba Rugi</a
          >
        </li>
        <li class="nav-item">
          <a
            @click="selectType('financial_balance')"
            class="nav-link"
            :class="type == 'financial_balance' ? 'active show' : ''"
            id="profile-tab3"
            data-toggle="tab"
            href="#profile3"
            role="tab"
            aria-controls="profile"
            aria-selected="true"
            >Neraca</a
          >
        </li>
        <li class="nav-item">
          <a
            @click="selectType('sale_overview')"
            class="nav-link"
            :class="type == 'sale_overview' ? 'active show' : ''"
            id="profile-tab3"
            data-toggle="tab"
            href="#profile3"
            role="tab"
            aria-controls="profile"
            aria-selected="true"
            >Sale Overview</a
          >
        </li>
        <li class="nav-item">
          <a
            @click="selectType('general_ledger')"
            class="nav-link"
            :class="type == 'general_ledger' ? 'active show' : ''"
            id="profile-tab3"
            data-toggle="tab"
            href="#profile3"
            role="tab"
            aria-controls="profile"
            aria-selected="true"
            >Buku Besar</a
          >
        </li>
      </ul>
      <div class="d-flex justify-content-start mb-4">
        <date-range-picker
          @update="updateValues"
          v-model="dateRange"
          opens="right"
        >
        </date-range-picker>
      </div>
      <div v-if="type == 'income_statement'">
        <div class="row">
          <div class="col-sm-6">
            <div class="card card-statistic-1">
              <div class="card-icon bg-success">
                <i class="fas fa-file-invoice-dollar"></i>
              </div>
              <div class="card-wrap">
                <div class="card-header">
                  <h4>Revenue Total</h4>
                </div>
                <div class="card-body">
                  Rp. {{ formatPrice(revenue_total) }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card card-statistic-1">
              <div class="card-icon bg-warning">
                <i class="fas fa-file-invoice-dollar"></i>
              </div>
              <div class="card-wrap">
                <div class="card-header">
                  <h4>Net Profit</h4>
                </div>
                <div class="card-body">Rp. {{ formatPrice(net_profit) }}</div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card card-statistic-1">
              <div class="card-icon bg-primary">
                <i class="fas fa-file-invoice-dollar"></i>
              </div>
              <div class="card-wrap">
                <div class="card-header">
                  <h4>HPP Total</h4>
                </div>
                <div class="card-body">Rp. {{ formatPrice(hpp_total) }}</div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card card-statistic-1">
              <div class="card-icon bg-danger">
                <i class="fas fa-file-invoice-dollar"></i>
              </div>
              <div class="card-wrap">
                <div class="card-header">
                  <h4>Cost Total</h4>
                </div>
                <div class="card-body">Rp. {{ formatPrice(cost_total) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <table>
                  <thead>
                    <tr>
                      <th>Account</th>
                      <th>Debit</th>
                      <th>Credit</th>
                    </tr>
                  </thead>
                  <tbody v-for="(report, i) in reports" :key="'A' + i">
                    <tr>
                      <td>{{ report.account_code }}</td>
                      <td>
                        <p v-if="report.type == 'DEBIT'">
                          Rp. {{ formatPrice(report.total_amount) }}
                        </p>
                      </td>
                      <td>
                        <p v-if="report.type == 'CREDIT'">
                          Rp. {{ formatPrice(report.total_amount) }}
                        </p>
                      </td>
                    </tr>
                    <template v-for="(child, x) in report.account_code_childs">
                      <tr :key="'B' + x">
                        <td class="pl-3">
                          {{ child.account_code }}
                        </td>
                        <td>
                          <p v-if="child.type == 'DEBIT'">
                            Rp. {{ formatPrice(child.total_amount) }}
                          </p>
                        </td>
                        <td>
                          <p v-if="child.type == 'CREDIT'">
                            Rp. {{ formatPrice(child.total_amount) }}
                          </p>
                        </td>
                      </tr>
                      <template
                        v-for="(child2, y) in child?.account_code_childs"
                      >
                        <tr :key="'C' + y">
                          <td class="pl-5">
                            {{ child2.account_code }}
                          </td>
                          <td>
                            <p v-if="child2.type == 'DEBIT'">
                              Rp. {{ formatPrice(child2.total_amount) }}
                            </p>
                          </td>
                          <td>
                            <p v-if="child2.type == 'CREDIT'">
                              Rp. {{ formatPrice(child2.total_amount) }}
                            </p>
                          </td>
                        </tr>
                        <tr
                          v-for="(child3, d) in child2?.account_code_childs"
                          :key="'D' + d"
                        >
                          <td class="pl-10">
                            {{ child3.account_code }}
                          </td>
                          <td>
                            <p v-if="child3.type == 'DEBIT'">
                              Rp. {{ formatPrice(child3.total_amount) }}
                            </p>
                          </td>
                          <td>
                            <p v-if="child3.type == 'CREDIT'">
                              Rp. {{ formatPrice(child3.total_amount) }}
                            </p>
                          </td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="type == 'financial_balance'">
        <div class="row">
          <div class="col-sm-6">
            <div class="card card-statistic-1">
              <div class="card-icon bg-success">
                <i class="fas fa-file-invoice-dollar"></i>
              </div>
              <div class="card-wrap">
                <div class="card-header">
                  <h4>Credit Total</h4>
                </div>
                <div class="card-body">Rp. {{ formatPrice(credit_total) }}</div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card card-statistic-1">
              <div class="card-icon bg-warning">
                <i class="fas fa-file-invoice-dollar"></i>
              </div>
              <div class="card-wrap">
                <div class="card-header">
                  <h4>Debit Total</h4>
                </div>
                <div class="card-body">Rp. {{ formatPrice(debit_total) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <table>
                  <thead>
                    <tr>
                      <th>Account</th>
                      <th>Debit</th>
                      <th>Credit</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(report, i) in financialBalances"
                    :key="'A' + i"
                  >
                    <tr>
                      <td>{{ report.account_code }}</td>
                      <td>
                        <p v-if="report.type == 'DEBIT'">
                          Rp. {{ formatPrice(report.total_amount) }}
                        </p>
                      </td>
                      <td>
                        <p v-if="report.type == 'CREDIT'">
                          Rp. {{ formatPrice(report.total_amount) }}
                        </p>
                      </td>
                    </tr>
                    <template v-for="(child, x) in report.account_code_childs">
                      <tr :key="'B' + x">
                        <td class="pl-3">
                          {{ child.account_code }}
                        </td>
                        <td>
                          <p v-if="child.type == 'DEBIT'">
                            Rp. {{ formatPrice(child.total_amount) }}
                          </p>
                        </td>
                        <td>
                          <p v-if="child.type == 'CREDIT'">
                            Rp. {{ formatPrice(child.total_amount) }}
                          </p>
                        </td>
                      </tr>
                      <template
                        v-for="(child2, y) in child?.account_code_childs"
                      >
                        <tr :key="'CA' + y">
                          <td class="pl-5">
                            {{ child2.account_code }}
                          </td>
                          <td>
                            <p v-if="child2.type == 'DEBIT'">
                              Rp. {{ formatPrice(child2.total_amount) }}
                            </p>
                          </td>
                          <td>
                            <p v-if="child2.type == 'CREDIT'">
                              Rp. {{ formatPrice(child2.total_amount) }}
                            </p>
                          </td>
                        </tr>
                        <tr
                          v-for="(child3, d) in child2?.account_code_childs"
                          :key="'D' + d"
                        >
                          <td class="pl-10">
                            {{ child3.account_code }}
                          </td>
                          <td>
                            <p v-if="child3.type == 'DEBIT'">
                              Rp. {{ formatPrice(child3.total_amount) }}
                            </p>
                          </td>
                          <td>
                            <p v-if="child3.type == 'CREDIT'">
                              Rp. {{ formatPrice(child3.total_amount) }}
                            </p>
                          </td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td>Rp. {{ formatPrice(debit_total) }}</td>
                      <td>Rp. {{ formatPrice(credit_total) }}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="type == 'general_ledger'">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <table>
                  <thead>
                    <tr>
                      <th>Account</th>
                      <th>Debit</th>
                      <th>Credit</th>
                    </tr>
                  </thead>
                  <tbody v-for="(report, i) in generalLedger" :key="'A' + i">
                    <tr>
                      <td>{{ report.account_code }}</td>
                      <td>
                        <p v-if="report.type == 'DEBIT'">
                          Rp. {{ formatPrice(report.total_amount) }}
                        </p>
                      </td>
                      <td>
                        <p v-if="report.type == 'CREDIT'">
                          Rp. {{ formatPrice(report.total_amount) }}
                        </p>
                      </td>
                    </tr>
                    <template v-for="(child, x) in report.account_code_childs">
                      <tr :key="'B' + x">
                        <td class="pl-3">
                          {{ child.account_code }}
                        </td>
                        <td>
                          <p v-if="child.type == 'DEBIT'">
                            Rp. {{ formatPrice(child.total_amount) }}
                          </p>
                        </td>
                        <td>
                          <p v-if="child.type == 'CREDIT'">
                            Rp. {{ formatPrice(child.total_amount) }}
                          </p>
                        </td>
                      </tr>
                      <template
                        v-for="(child2, y) in child?.account_code_childs"
                      >
                        <tr :key="'C' + y">
                          <td class="pl-5">
                            {{ child2.account_code }}
                          </td>
                          <td>
                            <p v-if="child2.type == 'DEBIT'">
                              Rp. {{ formatPrice(child2.total_amount) }}
                            </p>
                          </td>
                          <td>
                            <p v-if="child2.type == 'CREDIT'">
                              Rp. {{ formatPrice(child2.total_amount) }}
                            </p>
                          </td>
                        </tr>
                        <tr
                          v-for="(child3, d) in child2?.account_code_childs"
                          :key="'D' + d"
                        >
                          <td class="pl-10">
                            {{ child3.account_code }}
                          </td>
                          <td>
                            <p v-if="child3.type == 'DEBIT'">
                              Rp. {{ formatPrice(child3.total_amount) }}
                            </p>
                          </td>
                          <td>
                            <p v-if="child3.type == 'CREDIT'">
                              Rp. {{ formatPrice(child3.total_amount) }}
                            </p>
                          </td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col-sm-6">
            <div class="card card-statistic-1">
              <div class="card-icon bg-success">
                <i class="fas fa-file-invoice-dollar"></i>
              </div>
              <div class="card-wrap">
                <div class="card-header">
                  <h4>Total Gross Sale</h4>
                </div>
                <div class="card-body">
                  Rp. {{ formatPrice(gross_sale_total) }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card card-statistic-1">
              <div class="card-icon bg-warning">
                <i class="fas fa-file-invoice-dollar"></i>
              </div>
              <div class="card-wrap">
                <div class="card-header">
                  <h4>Total Net Sale</h4>
                </div>
                <div class="card-body">
                  Rp. {{ formatPrice(net_sale_total) }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card card-statistic-1">
              <div class="card-icon bg-primary">
                <i class="fas fa-file-invoice-dollar"></i>
              </div>
              <div class="card-wrap">
                <div class="card-header">
                  <h4>Total Order</h4>
                </div>
                <div class="card-body">Rp. {{ formatPrice(order_total) }}</div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card card-statistic-1">
              <div class="card-icon bg-danger">
                <i class="fas fa-file-invoice-dollar"></i>
              </div>
              <div class="card-wrap">
                <div class="card-header">
                  <h4>Product Sale Order</h4>
                </div>
                <div class="card-body">
                  Rp. {{ formatPrice(product_sale_total) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h4>Top Items</h4>
              </div>
              <div class="card-body p-0">
                <div class="table-responsive">
                  <table class="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Sold</th>
                        <th>Image</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(top, i) in top_items" :key="i">
                        <td>{{ top.name }}</td>
                        <td>{{ top.sold }}</td>
                        <td>
                          <img :src="top.image" alt="" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { ProfileRepository } from '../../repositories/RepositoryFactory';
import { apiGet } from '../../services/api';
import axios from 'axios';
import moment from 'moment';

export default {
  name: 'Report',
  data() {
    return {
      search: '',
      name: 'Report',
      reports: [],
      loading: false,
      venue_id: 1,
      operating_cost_total: null,
      purchase_total: null,
      revenue_total: null,
      net_profit: null,
      hpp_total: null,
      cost_total: null,
      type: 'income_statement',
      credit_total: null,
      debit_total: null,
      financialBalances: [],
      saleOverview: [],
      sale_credit_total: null,
      sale_debit_total: null,
      gross_sale_total: null,
      net_sale_total: null,
      order_total: null,
      product_sale_total: null,
      latest_orders: [],
      top_items: [],
      generalLedger: [],

      dateRange: {
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().endOf('month').format('YYYY-MM-DD'),
      },
    };
  },
  mounted() {
    ProfileRepository.get().then(({ data }) => {
      if (data.data.venue_id) {
        this.venue_id = data.data.venue_id;
      }
    });
    this.getReport();
  },
  methods: {
    updateValues(type) {
      this.dateRange = {
        startDate: moment(type.startDate).format('YYYY-MM-DD'),
        endDate: moment(type.endDate).format('YYYY-MM-DD'),
      };

      if (this.type == 'income_statement') {
        this.getReport();
      } else if (this.type == 'financial_balance') {
        this.getFinancialBalance();
      } else if (this.type == 'general_ledger') {
        this.getFinancialBalance();
      } else {
        this.getSaleOverview();
      }
    },
    selectType(value) {
      this.type = value;
      if (value == 'financial_balance') {
        this.getFinancialBalance();
      } else if (value == 'sale_overview') {
        this.getSaleOverview();
      } else if (value == 'general_ledger') {
        this.getGeneralLedger();
      } else {
        this.getReport();
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    getGeneralLedger() {
      axios
        .get(this.$store.state.api + 'report/general_ledger', {
          headers: {
            Authorization: this.$store.state.token,
          },
          params: {
            start_date: this.dateRange.startDate,
            end_date: this.dateRange.endDate,
          },
        })
        .then(({ data }) => {
          let res = data.data;
          this.loading = false;
          this.generalLedger = res.accounts;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSaleOverview() {
      axios
        .get(this.$store.state.api + 'report/sale_overview', {
          headers: {
            Authorization: this.$store.state.token,
          },
          params: {
            venue_id: this.venue_id,
            start_date: this.dateRange.startDate,
            end_date: this.dateRange.endDate,
          },
        })
        .then(({ data }) => {
          let res = data.data;
          this.loading = false;
          this.gross_sale_total = res.gross_sale_total;
          this.net_sale_total = res.net_sale_total;
          this.order_total = res.order_total;
          this.product_sale_total = res.product_sale_total;
          this.top_items = res.top_items;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getFinancialBalance() {
      axios
        .get(this.$store.state.api + 'report/financial_balance', {
          headers: {
            Authorization: this.$store.state.token,
          },
          params: {
            start_date: this.dateRange.startDate,
            end_date: this.dateRange.endDate,
          },
        })
        .then(({ data }) => {
          let res = data.data;
          this.loading = false;
          this.financialBalances = res.accounts;
          this.debit_total = res.debit_total;
          this.credit_total = res.credit_total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getReport() {
      this.loading = true;
      axios
        .get(this.$store.state.api + 'report/income_statement', {
          headers: {
            Authorization: this.$store.state.token,
          },
          params: {
            start_date: this.dateRange.startDate,
            end_date: this.dateRange.endDate,
          },
        })
        .then(({ data }) => {
          let res = data.data;
          this.loading = false;
          this.reports = res.accounts;
          this.revenue_total = res.revenue_total;
          this.net_profit = res.net_profit;
          this.hpp_total = res.hpp_total;
          this.cost_total = res.cost_total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
}
table,
table td {
  border: 0;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  border-collapse: separate;
  border-spacing: 0;
  border-bottom: 1px solid #ccc;
  margin: 0 0 2em;
}

table {
  line-height: 110%;
  width: 100%;

  th,
  td {
    padding: 1rem;
  }
  td.pl-3 {
    padding-left: 2rem !important;
  }
  td.pl-10 {
    padding-left: 4rem;
  }
}

table table td:nth-child(1) {
  padding-left: 20%;
}
</style>
